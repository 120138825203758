import React from 'react';
import {
  CodeSnippet,
  Paragraph,
  PlatformTab,
  Section,
  SectionSubhead,
  PropList,
  PropListItem,
  PageNavigation,
  PageNavigationLink,
} from 'docComponents';
import { Text, Subhead } from 'hudl-uniform-ui-components';

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Clearfix</PageNavigationLink>
        <PageNavigationLink>Media Query</PageNavigationLink>
        <PageNavigationLink>Micro Layout</PageNavigationLink>
        <PageNavigationLink>Spinner</PageNavigationLink>
      </PageNavigation>
      <Section title="Clearfix">
        <Paragraph>
          Apply this to a container if the child elements within are floated.
        </Paragraph>
        <CodeSnippet
          code="@include clearfix;"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.clearfix.scss"
        />
      </Section>
      <Section title="Media Query">
        <Paragraph>
          The media query mixin provides a consistent easy method of writing
          less verbose Media Queries with variables or using straight figures
          (eg 30rem). The mixin only accepts rem values or variables with rem
          values.
        </Paragraph>

        <CodeSnippet
          code="@include mq( $bp-1, $bp-2 );"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.mediaquery.scss"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$bp-1" types={['number']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>
                This is the smallest breakpoint for a media query, the point at
                which the media query starts peforming its magic.
              </Text>
            </PropListItem>

            <PropListItem name="$bp-2" types={['number']}>
              <Text>
                Default value: <code>null</code>
              </Text>
              <Text>
                This is the higher range for a given breakpoint. the point at
                which the styles are no longer applied.
              </Text>
            </PropListItem>
          </PropList>

          <div className="uni-margin--two--top">
            <SectionSubhead>Specific use cases</SectionSubhead>

            <Subhead className="uni-margin--one--top">
              Min-width media query
            </Subhead>
            <CodeSnippet
              code="@include mq( 20rem );"
              platform="css"
              disableCodeEditing
              gitHubLink="/mixins/_mix.mediaquery.scss"
            />

            <Subhead className="uni-margin--one--top uni-margin--half--btm">
              Max-width media query
            </Subhead>
            <Text>
              While writing CSS from a mobile first context is preferred, there
              are instances where a max-width media query is useful and totally
              acceptable. This accopmlished by passing <code>max</code> as an
              argument for one of the mixin parameters.
            </Text>
            <CodeSnippet
              code="@include mq( max, 40rem );"
              platform="css"
              disableCodeEditing
              gitHubLink="/mixins/_mix.mediaquery.scss"
            />

            <Subhead className="uni-margin--one--top uni-margin--half--btm">
              Bracketed media query
            </Subhead>
            <Text>
              Instances where you want to set a range where the styles would be
              applied.
            </Text>
            <CodeSnippet
              code="@include mq( 20rem, 40rem );"
              platform="css"
              disableCodeEditing
              gitHubLink="/mixins/_mix.mediaquery.scss"
            />
          </div>
        </Section>
      </Section>
      <Section title="Micro Layout">
        <Paragraph>
          The micro layout mixin allows you to quickly build grids of items.
        </Paragraph>

        <CodeSnippet
          code="@include micro-layout($num, $margin, $target);"
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.microlayout.scss"
        />

        <Section title="Parameters">
          <PropList>
            <PropListItem name="$num" types={['number']}>
              <Text>
                Default value: <code>2</code>
              </Text>
              <Text>The number of items you want per row.</Text>
            </PropListItem>

            <PropListItem name="$margin" types={['number']}>
              <Text>
                Default value: <code>$ml-margin</code> (1.5rem)
              </Text>
              <Text>The margin between items in a row.</Text>
            </PropListItem>

            <PropListItem name="$target" types={['class name']}>
              <Text>
                Default value: <code>$ml-target</code> (.l-grid__item)
              </Text>
              <Text>This is the repeating target item within the grid.</Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
      <Section title="Spinner">
        <CodeSnippet
          code={`@include css-spinner(
  $size,
  $bg-color,
  $on-bg,
  $speed,
  $thickness
);`}
          platform="css"
          disableCodeEditing
          gitHubLink="/mixins/_mix.spinners.scss"
        />
        <Section title="Parameters">
          <PropList>
            <PropListItem name="$size" types={['number']}>
              <Text>
                Default value: <code>$spinner-size</code> (2rem)
              </Text>
              <Text>The width and height of the spinner area.</Text>
            </PropListItem>

            <PropListItem name="$bg-color" types={['color variable']}>
              <Text>
                Default value: <code>$spinner-color-default</code>{' '}
                ($color-brand-orange)
              </Text>
              <Text>The color of the spinner.</Text>
            </PropListItem>

            <PropListItem name="$on-bg" types={['bool']}>
              <Text>
                Default value: <code>false</code>
              </Text>
              <Text>
                If the spinner is on a background color, change this to true. A
                contrast function will return a white spinner, if needed.
              </Text>
            </PropListItem>

            <PropListItem name="$speed" types={['time']}>
              <Text>
                Default value: <code>$spinner-timing</code> (0.7s)
              </Text>
              <Text>The time it takes a spinner to complete a revolution.</Text>
            </PropListItem>

            <PropListItem name="$thickness" types={['number']}>
              <Text>
                Default value: <code>($size * 0.2)</code>
              </Text>
              <Text>
                A specific value that controls how thick a spinner is.
              </Text>
            </PropListItem>
          </PropList>
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
