import React from 'react';
import { PageWithSubNav, PageHero, PlatformTabs } from 'docComponents';
import {
  AndroidTab,
  AppleTab,
  OtherTab,
  ReactNativeTab,
  WebTab,
} from './platforms/_index.js';
import pageHeroData from '../../../../data/pages/resources.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="General Code Helpers"
      subnav="resources">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Code Helpers"
        tierThree="General"
      />

      <PlatformTabs>
        <WebTab type="web" />
        <ReactNativeTab type="reactnative" />
        <AndroidTab type="android" />
        <AppleTab type="apple" />
        <OtherTab type="other" />
      </PlatformTabs>
    </PageWithSubNav>
  );
};

export default IndexPage;
